import React from 'react';
import '../styles/contact.styles.css';
import ContactForm from '../components/ContactForm/ContactForm';

const Contact = () => {
  return (
    <>
      <ContactForm />
    </>
  );
};

export default Contact;
