import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
        <p className='copyright'>© 2024</p>
    </div>
  )
}

export default Footer